import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '70vh',
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontSize: { xs: '3rem', md: '4.5rem' },
            fontWeight: 'bold',
            mb: 4,
            background: 'linear-gradient(45deg, #1976d2 30%, #21CBF3 90%)',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          WELCOME TO BARGN
        </Typography>
        
        <Typography
          variant="h5"
          sx={{
            mb: 4,
            color: 'text.secondary',
            maxWidth: '800px'
          }}
        >
          Your Premier Destination for Quality and Innovation
        </Typography>

        <Button
          variant="contained"
          size="large"
          onClick={() => navigate('/coa')}
          sx={{
            mt: 2,
            px: 4,
            py: 1.5,
            fontSize: '1.2rem',
            borderRadius: '30px',
            background: 'linear-gradient(45deg, #1976d2 30%, #21CBF3 90%)',
            boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
          }}
        >
          View COA System
        </Button>
      </Box>
    </Container>
  );
};

export default Home;
