import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Collapse,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GetAppIcon from '@mui/icons-material/GetApp';

const CoaCard = ({ coa }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDownload = () => {
    // TODO: Implement download functionality
    window.open(coa.fileUrl, '_blank');
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div">
          {coa.productName}
        </Typography>
        <Typography color="text.secondary">
          Type: {coa.type}
        </Typography>
        <Typography color="text.secondary">
          Date: {new Date(coa.uploadDate).toLocaleDateString()}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          startIcon={<ExpandMoreIcon />}
          onClick={handleExpandClick}
          sx={{
            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: '0.3s',
          }}
        >
          {expanded ? 'Show Less' : 'Show More'}
        </Button>
        <Button startIcon={<GetAppIcon />} onClick={handleDownload}>
          Download
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Details:</Typography>
          <Box component="pre" sx={{ whiteSpace: 'pre-wrap' }}>
            {coa.details}
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default CoaCard;
