import React, { useState } from 'react';
import {
  Container,
  Grid,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import CoaCard from '../components/CoaCard';

const Dashboard = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [productFilter, setProductFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');

  // Mock data - replace with actual API call
  const coaFiles = [
    {
      id: 1,
      productName: 'Mini Mart 1g Disposable',
      type: 'THCA',
      uploadDate: '2025-02-19',
      details: 'Sample COA details...',
      fileUrl: '#',
    },
    // Add more mock data as needed
  ];

  const filteredCoas = coaFiles.filter((coa) => {
    const matchesSearch = coa.productName
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesProduct = productFilter === 'all' || coa.productName === productFilter;
    const matchesType = typeFilter === 'all' || coa.type === typeFilter;
    return matchesSearch && matchesProduct && matchesType;
  });

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        COA Dashboard
      </Typography>
      
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Search COAs"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Product Filter</InputLabel>
              <Select
                value={productFilter}
                label="Product Filter"
                onChange={(e) => setProductFilter(e.target.value)}
              >
                <MenuItem value="all">All Products</MenuItem>
                <MenuItem value="Mini Mart 1g Disposable">Mini Mart 1g Disposable</MenuItem>
                {/* Add more product options */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Type Filter</InputLabel>
              <Select
                value={typeFilter}
                label="Type Filter"
                onChange={(e) => setTypeFilter(e.target.value)}
              >
                <MenuItem value="all">All Types</MenuItem>
                <MenuItem value="THCA">THCA</MenuItem>
                <MenuItem value="THCP">THCP</MenuItem>
                {/* Add more type options */}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        {filteredCoas.map((coa) => (
          <Grid item xs={12} key={coa.id}>
            <CoaCard coa={coa} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Dashboard;
